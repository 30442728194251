import React, { useEffect } from 'react';

const Ads = () => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.type = 'text/javascript';
//     script.src = `${window.location.protocol === 'https:' ? 'https://ads.serviceschoolhouse.com/www/delivery/ajs.php' : 'http://ads.serviceschoolhouse.com/www/delivery/ajs.php'}${window.innerWidth > 600 ?"?zoneid=12" : "?zoneid=11"}&cb=${Math.floor(Math.random() * 99999999999)}`;
//     document.body.appendChild(script);
//   }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin:"10px 0" }}>
      <div style={{ maxWidth: 'fit-content' }}>
    
     <iframe id='acb8cc3b' name='acb8cc3b' src={`https://ads.serviceschoolhouse.com/www/delivery/afr.php?zoneid=${window.innerWidth>600?12:11}&amp;cb=INSERT_RANDOM_NUMBER_HERE`} frameborder='0' scrolling='no' width='728' height='90' allow='autoplay'><a href='https://ads.serviceschoolhouse.com/www/delivery/ck.php?n=a992b110&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://ads.serviceschoolhouse.com/www/delivery/avw.php?zoneid=12&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a992b110' border='0' alt='' /></a></iframe>

      </div>
    </div>
  );
};

export default Ads;